import { Card, CardProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const KycCardStyled = styled(Card)<CardProps>(() => (
  {
    borderRadius: '30px',
    boxShadow: '0px 0px 20px 0px #0000001A',
    // position: 'relative',
    '.MuiCardActions-root': {
      padding: 'unset',
      paddingTop: '1rem'
    },
    '.MuiCardContent-root': {
      padding: '3rem'
    },
    '&::before': {
      content: "url('/assets/images/digiscan-logo.svg')",
      width: '2rem',
      height: '2rem',
      position: 'absolute',
      left: 'calc(50% - 55px)',
      top: '60px'
    }
  }
));

function KycCard(props: CardProps) {
  return <KycCardStyled {...props}>
    {props.children}
  </KycCardStyled>;
}

export default KycCard;

