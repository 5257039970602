import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './footer.scss';

function Footer() {
  const { t } = useTranslation();

  return <Stack direction='row' className='footer'>
    <Typography>{t('ALL_RIGHTS_RESERVED')}</Typography>
    <Stack direction='row' gap={2}>
      <Link to={''}>{t('PRIVACY_POLICY')}</Link>
      <Link to={''}>{t('TERMS_OF_SERVICE')}</Link>
      <Link to={''}>{t('COOKIES_POLICY')}</Link>
    </Stack>
  </Stack>
}

export default Footer;
