import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './AmlVerification.scss';

interface AMLVerificationProps {
  onNext: () => void;
}

export function AMLVerification({ onNext }: AMLVerificationProps) {
  const { t } = useTranslation();

  return (
    <Stack className='aml-verification-box'>
      <Typography variant='h2' id='step-title'>{t('AML_VERIFICATION')}</Typography>
      <Typography>
        {t('AML_VERIFICATION_DEAR_CUSTOMER')}
      </Typography>
      <Typography marginY={1}>
        {t('AML_VERIFICATION_FIRST_PARAGRAPH')}
      </Typography>
      <Typography marginY={1}>
        {t('AML_VERIFICATION_SECOND_PARAGRAPH')}
      </Typography>
      <Typography marginY={1}>
        {t('AML_VERIFICATION_THIRD_PARAGRAPH')}
        <a href={'https://safe2transact.com/'} className={'link privacy-link'}
           target={'_blank'}> www.safe2transact.com.</a>
      </Typography>
      <Typography marginY={1}>
        {t('AML_VERIFICATION_FORTH_PARAGRAPH')}
      </Typography>
      <Button variant='contained' onClick={onNext} className='next-btn'>
        {t('NEXT')}
      </Button>
    </Stack>
  );
}
