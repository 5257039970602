import { Box, CardContent, Stack, Step, StepButton, Stepper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { FullPageLoadingCircle } from '../../components/FullPageLoadingCircle/FullpageLoadingCircle';
import SwalAlert from '../../components/SwalAlert/SwalAlert';
import { CountryDto } from '../../models/admin/CountryDto';
import AMLQuestionAnswerDTO from '../../models/clientQuestionnaires/AMLQuestionAnswerDTO';
import ClientQuestionnairesDTO from '../../models/clientQuestionnaires/ClientQuestionnairesDTO';
import QuestionsType from '../../models/clientQuestionnaires/QuestionsType';
import { ClientDto } from '../../models/clients/ClientDto';
import { AMLQuestionService } from '../../repository/AMLQuestionService';
import { ClientTokenService } from '../../repository/ClientTokenService';
import CountryService from '../../repository/CountryService';
import { ProcedureService } from '../../repository/ProcedureService';
import QuestionsService from '../../repository/QuestionsService';
import KycCard from '../../shared/components/KycCard/KycCard';
import MainPage from '../../shared/pages/main-page/MainPage';
import { ErrorHandler } from '../../utils/ErrorHandler';
import './AMLProcedure.scss';
import { AMLQuestion } from './AMLQuestion/AMLQuestion';
import { AMLVerification } from './AMLVerification/AMLVerification';

export function AMLProcedure() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [userData, setUserData] = useState<ClientDto>();
  const [questions, setQuestions] = useState<ClientQuestionnairesDTO[]>([]);
  const { token } = useParams();
  const { register, setValue, getValues, watch } = useForm();
  const [isVerificationCompleted, setIsVerificationCompleted] = useState(false);
  const [questionTypesList, setQuestionTypeList] = useState<QuestionsType[]>([]);
  const [answerOnQuestions, setAnswerOnQuestion] = useState<AMLQuestionAnswerDTO[]>([]);
  const [countries, setCountries] = useState<CountryDto[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllQuestionType();
    getAllCountries();
  }, []);

  const getAllQuestionType = () => {
    QuestionsService.getAllQuestionsType()
      .then((data) => setQuestionTypeList(data.data));
  };

  const getAllCountries = () => {
    CountryService.getAllCountries()
      .then(response => setCountries(response.data));
  };

  const handleVerificationComplete = () => {
    setIsVerificationCompleted(true);
  };

  const navigateToCompletedProcedure = () => navigate('/aml-submitted');

  const validateToken = async (token: string) => {
    await ClientTokenService.validateClientToken(token)
      .then((response) => {
        setUserData(response.data);
      })
      .catch((e) => {
        const errorMessage = ErrorHandler.parseErrorMessage(e);
        const customMessage = t('START_NEW_AML_PROCEDURE');
        SwalAlert.errorAlert(t('ERROR'), errorMessage + ' ' + customMessage);
      });
  };

  const getPersonQuestions = () => {
    AMLQuestionService.getAMLPersonGroupQuestions(token).then(
      (response: ClientQuestionnairesDTO[]) => {
        setQuestions(response);
      }
    );
  };
  useEffect(() => {
    if (!token) {
      navigate('/error');
      return;
    }
    ProcedureService.existsAMLProcedureByClientToken(token).then((res) => {
      if (res == false) {
        validateToken(token).then(() => {
          getPersonQuestions();
        });
      } else {
        navigateToCompletedProcedure();
      }
    });
  }, []);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };
  const handleStep = (step: number) => () => {
    if (answerOnQuestions[step] !== undefined) {
      setActiveStep(step);
    }
  };
  const checkIfAnswered = (answer: AMLQuestionAnswerDTO) => {
    return !!((answer?.files && answer.files?.length > 0)
      || (answer?.countries && answer.countries?.length > 0)
      || answer?.freeTextAnswer
      || (answer?.nomenclatureEntryIds && answer.nomenclatureEntryIds.length > 0));

  };
  const getQuestionTypeByQuestionId = (id: number) => {
    const element = questionTypesList.find((qt) => qt.id === id);
    return element?.type;
  };

  const handleSaveAnswer = (index: number | string, amlAnswer: AMLQuestionAnswerDTO) => {
    if (typeof index == 'string') {
      index = parseInt(index);
    }
    answerOnQuestions[index - 1] = amlAnswer;
    setAnswerOnQuestion(answerOnQuestions);
  };

  const handleSubmitAmlProcedure = () => {
    setLoading(true);
    AMLQuestionService.createAMLProcedure(answerOnQuestions, token)
      .then(() => {
        setLoading(false);
        navigateToCompletedProcedure();
      }).catch((e) => {
      const errorMessage = ErrorHandler.parseErrorMessage(e);
      setLoading(false);
      SwalAlert.errorAlert(t('ERROR'), errorMessage);
    });
  };

  return <MainPage>
    <KycCard>
      <CardContent className='aml-procedure-card'>
        {!isVerificationCompleted ? (
          <AMLVerification onNext={handleVerificationComplete}/>
        ) : (
          <Stack className='aml-question-wrapper'>
            {questions.map(
              (question: ClientQuestionnairesDTO, index: number) =>
                activeStep === index && (
                  <AMLQuestion
                    key={index}
                    question={question.amlQuestionDTO}
                    type={question?.amlQuestionDTO?.amlQuestionType}
                    index={index + 1}
                    sizeQuestion={questions.length}
                    answers={question.nomenclatureEntryDtoList}
                    description={question.amlQuestionDTO.description}
                    onNext={handleNext}
                    answerOnQuestion={
                      answerOnQuestions && answerOnQuestions[index]
                    }
                    onBack={handleBack}
                    files={question.amlQuestionFileDtos}
                    register={register}
                    handleSubmit={handleSubmitAmlProcedure}
                    setValue={setValue}
                    getValues={getValues}
                    watch={watch}
                    userData={userData}
                    handleSaveAnswer={handleSaveAnswer}
                    countries={countries}>
                      <Stepper
                        nonLinear
                        activeStep={activeStep}
                        className='aml-stepper' connector={null}>
                        {questions.map((_: any, index: number) => (
                          <Step key={index} completed={checkIfAnswered(answerOnQuestions[index])}>
                            <StepButton color='inherit' onClick={handleStep(index)}/>
                          </Step>
                        ))}
                      </Stepper>
                  </AMLQuestion>)
            )}
          </Stack>
        )}
      </CardContent>
    </KycCard>
    <FullPageLoadingCircle loading={loading}/>
  </MainPage>;
}
