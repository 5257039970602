import {Button, Checkbox, FormControlLabel, Grid, Select, SelectChangeEvent, TextField} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import React, {useEffect, useState} from 'react';
import Swal from 'sweetalert2';
import FileUploadInput from '../../../components/FormFIelds/FIleUploadInput';
import SwalAlert from '../../../components/SwalAlert/SwalAlert';
import { PublicCountryResponseDto } from '../../../models/country/PublicCountryResponseDto';
import {
    KycProcedureLegalEntityAnswersRequest
} from '../../../models/legal_entity/KycProcedureLegalEntityAnswersRequest';
import CountryService from '../../../repository/CountryService';
import KYCProcedureService from '../../../repository/KYCProcedureService';
import ApplicationService from '../../../utils/ApplicationService';
import {ErrorHandler} from '../../../utils/ErrorHandler';
import {LegalEntityFormProps} from './LegalEntityFormProps';
import {useTranslation} from 'react-i18next';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import './LegalEntityForm.css';
import {LegalEntityFormDto} from "../../../models/legal_entity/LegalEntityFormDto";
import ShareholderService from "../../../repository/ShareholderService";
import LegalEntityFormService from "../../../repository/LegalEntityFormService";
import {KycSelectInputLabel} from "../../../shared/styled-components/KycSelectInputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

export function LegalEntityForm({
                                    email,
                                    token,
                                    onSave
                                }: LegalEntityFormProps) {
    const {t} = useTranslation();
    const [request, setRequest] = useState<KycProcedureLegalEntityAnswersRequest>({
        name: '',
        formId: '',
        registrationNumber: '',
        dateOfIncorporation: null,
        registeredAddress: '',
        businessAddress: '',
        phoneNumber: '',
        email: email ? email : '',
        companyWebsite: '',
        taxIdentificationNumber: '',
        isUsaTaxResident: false,
        certificatesOfIncorporation: [],
        memorandumAndArticlesOfAssociation: [],
        companySearch: [],
        visualManagementStructure: [],
        registeredCountryId: ''
    });
    const [legalEntityFormType, setLegalEntityFormType] = useState<LegalEntityFormDto[]>([]);
    const [emailError, setEmailError] = useState(false);
    const [countries, setCountries] = useState<PublicCountryResponseDto[]>([]);

    const getAllPublicCountries = () => {
      CountryService.getAllCountries().then((response) => {
        setCountries(response.data);
      });
    };
    useEffect(() => {
      getAllPublicCountries();

      LegalEntityFormService.allLegalEntityForms().then((res) => {
          setLegalEntityFormType(res);
      });
    }, []);

    const disableButton =
        request.name.trim() === '' ||
        request.formId === '' ||
        request.registrationNumber.trim() === '' ||
        request.dateOfIncorporation === null ||
        request.registeredAddress.trim() === '' ||
        request.businessAddress.trim() === '' ||
        request.phoneNumber.trim() === '' ||
        request.companyWebsite.trim() === '' ||
        request.email.trim() === '' ||
        request.taxIdentificationNumber.trim() === '' ||
        request.certificatesOfIncorporation.length === 0 ||
        request.memorandumAndArticlesOfAssociation.length === 0 ||
        request.companySearch.length === 0 ||
        request.visualManagementStructure.length === 0 ||
        request.registeredCountryId.trim() === '';

    const saveLegalEntityAnswers = () => {
        KYCProcedureService.createLegalEntityAnswers(token!, request).then((res) => {
            onSave();
        }).catch((e) => {
            const errorMessage = ErrorHandler.parseErrorMessage(e);
            let customMessage = t('ERROR_SAVING_LEGAL_ENTITY_ANSWERS');
            SwalAlert.errorAlert(t('ERROR'), errorMessage + ' ' + customMessage);
        });
    };
    const handleChangeCertificatesOfIncorporation = async (files: File[]) => {
        ApplicationService.filesToFilesDto(files).then((res) => {
            setRequest(prevState => ({...prevState, certificatesOfIncorporation: res}));
        });
    };
    const handleChangeMemorandumAndArticlesOfAssociation = async (files: File[]) => {
        ApplicationService.filesToFilesDto(files).then((res) => {
            setRequest(prevState => ({...prevState, memorandumAndArticlesOfAssociation: res}));
        });
    };
    const handleChangeCompanySearch = async (files: File[]) => {
        ApplicationService.filesToFilesDto(files).then((res) => {
            setRequest(prevState => ({...prevState, companySearch: res}));
        });
    };
    const handleChangeVisualManagementStructure = async (files: File[]) => {
      ApplicationService.filesToFilesDto(files).then((res) => {
        setRequest(prevState => ({ ...prevState, visualManagementStructure: res }));
      });
    };

    const handleChangeLegalEntityType = (event: SelectChangeEvent) => {
        setRequest(prevState => ({ ...prevState, formId: event.target.value }));
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRequest(prevState => ({ ...prevState, email: event.target.value }));
      if (event.target.validity.valid) {
        setEmailError(false);
      } else {
        setEmailError(true);
      }
    };

    return (
        <Grid container spacing={2} padding={4}>
            <Grid item xs={12} sm={12} lg={6}>
                <TextField
                    label={t('NAME')}
                    fullWidth={true}
                    value={request.name}
                    onChange={(event) => setRequest(prevState => ({...prevState, name: event.target.value}))}/>
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
                <FormControl fullWidth>
                    <KycSelectInputLabel>{t('FORM')}</KycSelectInputLabel>
                    <Select
                        id='entitylegalForm'
                        value={request.formId}
                        label={t('FORM')}
                        onChange={handleChangeLegalEntityType}
                    >
                        {
                            legalEntityFormType.map((e) => {
                                return (
                                    <MenuItem key={e.id} value={e.id}>{e.description}</MenuItem>
                                );
                            })
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
                <TextField
                    label={t('REGISTRATION_NUMBER')}
                    fullWidth={true}
                    value={request.registrationNumber}
                    onChange={(event) => setRequest(prevState => ({
                        ...prevState,
                        registrationNumber: event.target.value
                    }))}/>
            </Grid>
            {/* TODO::check if the date of incorporation is sending properly */}
            <Grid item xs={12} sm={12} lg={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        className='date_of_incorporation'
                        label={t('DATE_OF_INCORPORATION')}
                        value={request.dateOfIncorporation}
                        format='MMM D, YYYY'
                        onChange={(newValue) => setRequest(prevState => ({
                            ...prevState,
                            dateOfIncorporation: newValue
                        }))}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} lg={3}>
                <TextField
                    label={t('REGISTERED_ADDRESS')}
                    fullWidth={true}
                    value={request.registeredAddress}
                    onChange={(event) => setRequest(prevState => ({
                        ...prevState,
                        registeredAddress: event.target.value
                    }))}/>
            </Grid>
            <Grid item xs={12} sm={12} lg={3}>
              <div>
                <FormControl fullWidth className='proof-of-address-field'>
                  <Autocomplete
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    disablePortal
                    id='country'
                    onChange={(event,value) => setRequest(prevState => ({
                      ...prevState,
                      registeredCountryId: value ? value.id.toString() : ''
                    }))}
                    options={countries.map((country) => {
                      return {
                        label: country.name, id: country.id
                      };
                    })}
                    renderInput={(params) => <TextField {...params} label={t('REGISTERED_COUNTRY')}/>}/>
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
                <TextField
                    label={t('BUSINESS_ADDRESS')}
                    fullWidth={true}
                    value={request.businessAddress}
                    onChange={(event) => setRequest(prevState => ({
                        ...prevState,
                        businessAddress: event.target.value
                    }))}/>
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
                <TextField
                    type={'number'}
                    label={t('PHONE_NUMBER')}
                    fullWidth={true}
                    value={request.phoneNumber}
                    onChange={(event) => setRequest(prevState => ({...prevState, phoneNumber: event.target.value}))}/>
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
                <TextField
                    label={t('EMAIL')}
                    fullWidth={true}
                    value={request.email}
                    error={emailError}
                    helperText={emailError && t('EMAIL_CORRECT_FORMAT')}
                    inputProps={{
                      type: "email",
                    }}
                    onChange={handleEmailChange}/>
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
                <TextField
                    label={t('TAX_IDENTIFICATION_NUMBER')}
                    fullWidth={true}
                    value={request.taxIdentificationNumber}
                    onChange={(event) => setRequest(prevState => ({
                        ...prevState,
                        taxIdentificationNumber: event.target.value
                    }))}/>
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
                <TextField
                    label={t('COMPANY_WEBSITE')}
                    fullWidth={true}
                    value={request.companyWebsite}
                    onChange={(event) => setRequest(prevState => ({
                        ...prevState,
                        companyWebsite: event.target.value
                    }))}/>
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={request.isUsaTaxResident}
                            onChange={(event) => setRequest(prevState => ({
                                ...prevState,
                                isUsaTaxResident: event.target.checked
                            }))}
                            inputProps={{'aria-label': 'controlled'}}
                        />
                    }
                    label={t('IS_USA_TAX_RESIDENT')}/>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
                <FileUploadInput
                    classes='file-upload-box'
                    label={t('CERTIFICATES_OF_INCORPORATION')}
                    selectedFiles={request.certificatesOfIncorporation}
                    onFileChange={handleChangeCertificatesOfIncorporation}
                    index={0}
                    onSizeErrorModal={(errorMessage: string) => {
                        Swal.fire({
                            icon: 'error',
                            title: t('FILE_TOO_LARGE'),
                        });
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
                <FileUploadInput
                    classes='file-upload-box'
                    label={t('MEMORANDUM_AND_ARTICLES_OF_ASSOCIATION')}
                    selectedFiles={request.memorandumAndArticlesOfAssociation}
                    onFileChange={handleChangeMemorandumAndArticlesOfAssociation}
                    index={0}
                    onSizeErrorModal={(errorMessage: string) => {
                        Swal.fire({
                            icon: 'error',
                            title: t('FILE_TOO_LARGE'),
                        });
                    }}/>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
                <FileUploadInput
                    classes='file-upload-box'
                    label={t('COMPANY_SEARCH')}
                    selectedFiles={request.companySearch}
                    onFileChange={handleChangeCompanySearch}
                    index={0}
                    onSizeErrorModal={(errorMessage: string) => {
                        Swal.fire({
                            icon: 'error',
                            title: t('FILE_TOO_LARGE'),
                        });
                    }}/>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <FileUploadInput
                classes='file-upload-box'
                label={t('VISUAL_MANAGEMENT_STRUCTURE')}
                selectedFiles={request.visualManagementStructure}
                onFileChange={handleChangeVisualManagementStructure}
                index={0}
                onSizeErrorModal={(errorMessage: string) => {
                  Swal.fire({
                    icon: 'error',
                    title: t('FILE_TOO_LARGE'),
                  });
                }}/>
            </Grid>

            <Grid item xs={12} sm={12} lg={12}>
              <div className="disclaimer">*{t('LEGAL_ENTITY_DOCUMENTS_DISCLAIMER')}</div>
            </Grid>

            <Grid
                  item
                  xs={12}
                  className='legal-entity-next-button'
                  display='flex'>
                  <Button
                      disabled={disableButton}
                      variant='contained'
                      onClick={saveLegalEntityAnswers}>
                      {t('NEXT')}
                  </Button>
              </Grid>
        </Grid>
    );
}
