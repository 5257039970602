import { Box } from '@material-ui/core';
import { Button, Grid, Stack, TextField, Typography } from '@mui/material';
import SwalAlert from 'components/SwalAlert/SwalAlert';
import React, { ChangeEvent, PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import CountriesMultiSelect from '../../../components/CountriesMultiSelect/CountriesMultiSelect';
import CheckboxInput from '../../../components/FormFIelds/CheckboxInput';
import FileUploadInput from '../../../components/FormFIelds/FIleUploadInput';
import '../AMLProcedure.scss';
import RadioInput from '../../../components/FormFIelds/RadioInput';
import { CountryDto } from '../../../models/admin/CountryDto';
import { FileDTO } from '../../../models/aml/FileDTO';
import { FileHelper } from '../../../models/aml/FileHelper';
import AMLQuestionAnswerDTO from '../../../models/clientQuestionnaires/AMLQuestionAnswerDTO';
import AMLQuestionDTO from '../../../models/clientQuestionnaires/AMLQuestionDTO';
import AMLQuestionFileDTO from '../../../models/clientQuestionnaires/AMLQuestionFileDTO';
import { AnswersConstants } from '../../../models/clientQuestionnaires/constants/AnswersConstants';
import { QuestionType } from '../../../models/constants';
import CountryService from '../../../repository/CountryService';
import ApplicationService from '../../../utils/ApplicationService';
import './AMLQuestion.scss';

interface AMLQuestionProps extends PropsWithChildren {
  question: AMLQuestionDTO;
  type?: string;
  answers?: any;
  index?: number;
  description?: any;
  onNext: () => void;
  onBack: () => void;
  files?: any;
  register: any;
  handleSubmit: any;
  setValue: any;
  getValues: any;
  watch: any;
  userData: any;
  handleSaveAnswer: (index: number | string, e: AMLQuestionAnswerDTO) => void;
  answerOnQuestion: AMLQuestionAnswerDTO;
  sizeQuestion: number;
  countries: CountryDto[];
}

export function AMLQuestion({
  question,
  type,
  answers,
  description,
  index,
  onNext,
  onBack,
  files,
  register,
  handleSubmit,
  watch,
  setValue,
  getValues,
  userData,
  handleSaveAnswer,
  answerOnQuestion,
  sizeQuestion,
  countries,
  children
}: AMLQuestionProps) {
  const [selectedRadioChoice, setSelectedRadioChoice] = useState<string | null>(null);
  const { t } = useTranslation();
  const [selectedFilesArray, setSelectedFilesArray] = useState<FileHelper[]>([]);
  const [selectedCheckBoxChoice, setSelectedCheckBoxChoice] = useState<string[]>([]);
  const [textAnswer, setTextAnswer] = useState<string | null>('');
  const [isDisableButton, setIsDisableButton] = useState<boolean>(true);
  const [selectedCountries, setSelectedCountries] = useState<number[]>([]);
  const [showOtherField, setShowOtherField] = useState<boolean>(false);

  useEffect(() => {
    if (answerOnQuestion) {
      if (type === QuestionType.MULTIPLE_CHOICE) {
        setSelectedCheckBoxChoice(answerOnQuestion.nomenclatureEntryIds);
        setTextAnswer(answerOnQuestion.freeTextAnswer);
      } else if (type === QuestionType.SINGLE_CHOICE) {
        setSelectedRadioChoice(answerOnQuestion.nomenclatureEntryIds[0]);
        setTextAnswer(answerOnQuestion.freeTextAnswer);
      } else if (type === QuestionType.FREE_TEXT || type === QuestionType.NUMBER) {
        setTextAnswer(answerOnQuestion.freeTextAnswer);
      }
      if (answerOnQuestion.files) {
        let previousFiles: File[] = [];
        answerOnQuestion?.files.sort((e1, e2) => e1.index! - e2.index!);
        let i = -1;
        answerOnQuestion.files?.map((e: FileDTO, index) => {
          if (index === 0) {
            i = e.index!;
          }
          if (i !== e.index) {
            selectedFilesArray.push({ files: previousFiles, index: i });
            previousFiles = [];
            i = e.index!;
          }
          const mimeType = 'application/octet-stream'; // Adjust MIME type if needed
          const blob = ApplicationService.base64ToBlob(
            e?.base64Content,
            mimeType
          );
          const fileName = e.name;
          previousFiles.push(new File([blob], fileName, { type: mimeType }));

          if (index === answerOnQuestion.files?.length! - 1) {
            selectedFilesArray.push({ files: previousFiles, index: i });
          }
        });
      }
      if (answerOnQuestion.countries) {
        setSelectedCountries(answerOnQuestion.countries);
      }
    }
  }, []);

  async function saveAndSubmit() {
    await onSaveAnswerAndGoNext();
    handleSubmit();
  }

  useEffect(() => {
    if (type === QuestionType.MULTIPLE_CHOICE) {
      if (selectedCheckBoxChoice?.length > 0) {
        //can be changed with == files.length
        setIsDisableButton(false);
      } else if (selectedCountries.length > 0) {
        setIsDisableButton(false);
      } else {
        setIsDisableButton(true);
      }
    } else if (type === QuestionType.SINGLE_CHOICE) {
      if (selectedRadioChoice != null) {
        // if (files && files.length > 0) {
        //   if (selectedFilesArray.length > 0) {
        //     setIsDisableButton(false);
        //   } else {
        //     setIsDisableButton(true);
        //   }
        // } else {
        //   setIsDisableButton(false);
        // }
        setIsDisableButton(false);
      } else if (selectedCountries.length > 0) {
        setIsDisableButton(false);
      } else {
        setIsDisableButton(true);
      }
    } else if (type === QuestionType.FREE_TEXT || type === QuestionType.NUMBER) {
      if (textAnswer !== '') {
        setIsDisableButton(false);
      } else {
        setIsDisableButton(true);
      }
    }
  }, [
    selectedRadioChoice,
    selectedFilesArray,
    selectedCheckBoxChoice,
    textAnswer,
    selectedCountries
  ]);

  const handleFileChange = (files: File[], fileIndex: number) => {
    const MAX_FILE_SIZE = 10 * 1024 * 1024;
    const oversizedFiles = files.filter(file => file.size > MAX_FILE_SIZE);

    if (oversizedFiles.length > 0) {
      SwalAlert.errorAlert(t('FILE_TOO_LARGE'), t('FILE_SIZE_LIMIT', { size: '10 MB' }));
      return;
    }

    const newList = selectedFilesArray.filter((e) => {
      return fileIndex !== e.index;
    });

    setSelectedFilesArray([...newList, { files: files, index: fileIndex }]);
  };

  const handleRadioChange = (value: string) => {
    setSelectedRadioChoice(value);
    if(answers.find((element:any)=>element.name.toString() === AnswersConstants.OTHER)){
      let chosenAnswer = answers.find((element:any)=>element.id.toString() === value);
      if(chosenAnswer?.name === AnswersConstants.OTHER){
        setShowOtherField(true);
      }else{
        setShowOtherField(false);
        setTextAnswer('');
      }
    }
  };
  const handleTextFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTextAnswer(e.target.value);
  };
  const createAnswerCountries = async (countries: CountryDto[]) => {
    setSelectedCountries(countries.map(el => el.id));
    return selectedCountries;
  };

  const createAnswerAMLFileDTO = async () => {
    let filesDto: FileDTO[] = [];
    if (selectedFilesArray && selectedFilesArray.length > 0) {
      for (let i = 0; i < selectedFilesArray.length; i++) {
        for (let j = 0; j < selectedFilesArray[i].files.length; j++) {
          let base64FromFile: any = await ApplicationService.getBase64(
            selectedFilesArray[i].files[j]
          )
            .then((result) => {
              return result;
            })
            .catch(() => {
            });

          filesDto.push(
            new FileDTO({
              id: files && files.length > 0 ? files[selectedFilesArray[i].index].id : null,
              name: selectedFilesArray[i].files[j].name,
              mimeType: selectedFilesArray[i].files[j].type,
              base64Content: base64FromFile,
              index: selectedFilesArray[i].index
            })
          );
        }
      }
    }
    return filesDto ? filesDto : [];
  };

  const onSaveAnswerAndGoBack = async () => {
    const filesDto: FileDTO[] = await createAnswerAMLFileDTO();

    if (type === QuestionType.MULTIPLE_CHOICE) {
      handleSaveAnswer(
        index!,
        new AMLQuestionAnswerDTO(
          question.id,
          selectedCheckBoxChoice,
          textAnswer?.trim() === '' ? null : textAnswer,
          filesDto,
          selectedCountries
        )
      );
    } else if (type === QuestionType.SINGLE_CHOICE) {
      handleSaveAnswer(
        index!,
        new AMLQuestionAnswerDTO(
          question.id,
          selectedRadioChoice ? [selectedRadioChoice + ''] : [],
          textAnswer?.trim() === '' ? null : textAnswer,
          filesDto,
          selectedCountries
        )
      );
    } else if (type === QuestionType.FREE_TEXT || type === QuestionType.NUMBER) {
      handleSaveAnswer(
        index!,
        new AMLQuestionAnswerDTO(
          question.id,
          [],
          textAnswer,
          filesDto,
          null
        )
      );
    }
    onBack();
  };

  const onSaveAnswerAndGoNext = async () => {
    const filesDto: FileDTO[] = await createAnswerAMLFileDTO();

    if (type === QuestionType.MULTIPLE_CHOICE) {
      handleSaveAnswer(
        index!,
        new AMLQuestionAnswerDTO(
          question.id,
          selectedCheckBoxChoice,
          textAnswer?.trim() === '' ? null : textAnswer,
          filesDto,
          selectedCountries
        )
      );
    } else if (type === QuestionType.SINGLE_CHOICE) {
      handleSaveAnswer(
        index!,
        new AMLQuestionAnswerDTO(
          question.id,
          selectedRadioChoice ? [selectedRadioChoice + ''] : [],
          textAnswer?.trim() === '' ? null : textAnswer,
          filesDto,
          selectedCountries
        )
      );
    } else if (type === QuestionType.FREE_TEXT || type === QuestionType.NUMBER) {
      handleSaveAnswer(
        index!,
        new AMLQuestionAnswerDTO(
          question.id,
          [],
          textAnswer,
          filesDto,
          null
        )
      );
    }
    onNext();
  };

  const onHandleSelectedCheckBoxChoice = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedCheckBoxChoice(
      selectedCheckBoxChoice.filter((element) => element === e.target.value)
        .length > 0
        ? selectedCheckBoxChoice.filter((element) => element !== e.target.value)
        : [...selectedCheckBoxChoice, e.target.value]
    );
    if(answers.find((element:any)=>element.name.toString() === AnswersConstants.OTHER)){
      let chosenAnswer = answers.find((element:any)=>element.id.toString() === e.target.value);
      if(chosenAnswer?.name === AnswersConstants.OTHER){
        if(selectedCheckBoxChoice.find((element)=>element === e.target.value)){
          setSelectedCheckBoxChoice(selectedCheckBoxChoice.filter((element) => element !== e.target.value));
          setShowOtherField(false);
        }else{
          setSelectedCheckBoxChoice([e.target.value]);
          setShowOtherField(true);
        }
      }else{
        let otherAnswer = answers.find((element:any)=>element.name.toString() === AnswersConstants.OTHER);
        let otherAnswerExistsSelected = selectedCheckBoxChoice.find((element)=>element === otherAnswer.id.toString());
        if (otherAnswerExistsSelected){
          setSelectedCheckBoxChoice([...selectedCheckBoxChoice.filter((element) => element !== otherAnswerExistsSelected), e.target.value]);
          setTextAnswer('');
          setShowOtherField(false);
        }
      }
    }
  };

  return (
    <Stack sx={{ justifyContent: 'space-between', flex: 1 }}>
      <Stack gap={1}>
        <Typography variant='h2' id='step-title'>
          {t('QUESTION')} {index} / {sizeQuestion}
        </Typography>
        <Typography className='question-title'>
          <span className='question-order'>{index}</span> {question.title}?
        </Typography>
        {description && <Typography>{description}</Typography>}
        {(type === QuestionType.FREE_TEXT) && (
          <TextField
            id={question.title}
            {...register(question.title, { required: true })}
            fullWidth={true}
            onChange={handleTextFieldChange}
          />)
        }
        {type === QuestionType.NUMBER && (
            <TextField
              id={question.title}
              type='number'
              {...register(question.title, { required: true })}
              fullWidth={true}
              onChange={handleTextFieldChange}
            />
        )}
        {type === QuestionType.MULTIPLE_CHOICE && (
          <div>
            {answers &&
              answers.length > 0 &&
              answers?.map((answer: any, index: number) => (
                <div key={index}>
                  <>
                    <CheckboxInput
                      label={answer?.name}
                      name={answer?.name?.substring(0, 10) + '' + index}
                      register={register}
                      value={answer.id}
                      isChecked={selectedCheckBoxChoice.includes(
                        answer.id.toString()
                      )}
                      watch={watch}
                      onChange={onHandleSelectedCheckBoxChoice}/>
                    {showOtherField && answer?.value.toString() === AnswersConstants.OTHER &&
                      <TextField
                        id={question.title}
                        {...register(question.title, { required: true })}
                        fullWidth={true}
                        onChange={handleTextFieldChange}
                      />
                    }
                  </>
                </div>
              ))}
          </div>
        )}
        {type === QuestionType.SINGLE_CHOICE && (
          <div>
            {answers &&
              answers.length > 0 &&
              answers?.map((answer: any, index: number) => (
                <div key={index}>
                  <RadioInput
                    label={answer.name}
                    name={answer.name}
                    value={answer.id}
                    register={register}
                    selectedValue={selectedRadioChoice}
                    onChange={handleRadioChange}/>
                  {showOtherField && answer?.value.toString() === AnswersConstants.OTHER &&
                    <div>
                      <TextField
                        label={t('IF_OTHER')}
                        id={question.title}
                        {...register(question.title, { required: true })}
                        fullWidth={true}
                        multiline
                        onChange={handleTextFieldChange}
                      />
                    </div>
                  }
                </div>
              ))}
          </div>
        )}
        {question?.useCountryList === true &&
          <CountriesMultiSelect label={'PLEASE_CHOOSE_AN_OPTION'} key={question.id}
                                options={countries} selectedOptions={selectedCountries}
                                onChange={createAnswerCountries}/>
        }
        {files?.map((file: AMLQuestionFileDTO, index: number) => {
          return (
            <Box key={index}>
              <div
                key={index}
                style={{ paddingBottom: '15px', paddingTop: '15x', maxWidth: '32rem' }}>
                <FileUploadInput
                  label={file.title}
                  selectedFiles={selectedFilesArray?.find((e) => e.index === index)?.files!}
                  onFileChange={handleFileChange}
                  index={index}
                  onSizeErrorModal={(errorMessage: string) => {
                    Swal.fire({
                      icon: 'error',
                      title: t('FILE_TOO_LARGE'),
                    });
                  }}
                  name={`file-${index}`}
                  register={register}
                  watch={watch}/>
              </div>
            </Box>
          );
        })}
      </Stack>
      <Box className='footer-content'>
        <div>
          { index && index > 1 &&
            <Button variant='contained' onClick={onSaveAnswerAndGoBack}>{t('BACK')}</Button>
          }
        </div>
       {children}
        <div>
          {
            sizeQuestion !== index ? (
              <Button
                variant='contained'
                onClick={() => {
                  onSaveAnswerAndGoNext();
                }}
                disabled={isDisableButton}>
                {t('NEXT')}
              </Button>
            ) : (
              <Button
                variant='contained'
                onClick={() => {
                  saveAndSubmit();
                }}
                disabled={isDisableButton}>
                {t('SUBMIT')}
              </Button>
            )
          }
        </div>
      </Box>
    </Stack>
  );
}
