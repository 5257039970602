import CloseIcon from '@mui/icons-material/Close';
import { Box, Stack, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import { ReactNode, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CountryDto } from '../../models/admin/CountryDto';
import VirtualizedListBox from '../../shared/components/VirtualizedList/VirtualizedListBox';
import { CountriesMultiSelectProps } from './CountriesMultiSelectProps';
import './countriesMultiSelect.scss';

function CountriesMultiSelect(props: CountriesMultiSelectProps) {
  const { t } = useTranslation();
  const [selectedCountries, setSelectedCountries] = useState<CountryDto[]>([]);

  useEffect(() => {
    if (!props.options || !props.selectedOptions) { return; }
    const selectedOptions = props.options.filter(el => props.selectedOptions.includes(el.id));
    setSelectedCountries(selectedOptions);
  }, [props.selectedOptions, props.options]);

  const handleChange = (event: SyntheticEvent, value: CountryDto | null) => {
    if (!value) { return; }
    setSelectedCountries(prev => {
      const hasCountry = prev.find(el => el.id === value.id);
      const selectedCountries = hasCountry ? prev : [...prev, value];
      props.onChange(selectedCountries);
      return selectedCountries;
    });
  };

  const removeCountry = (country: CountryDto) => {
    setSelectedCountries(prev => {
      const countries = prev.filter(el => el.id !== country.id);
      props.onChange(countries);
      return countries;
    });
  }

  return (
    <Stack id='countries-multi-select'>
      <Box className='select-box'>
        <Autocomplete fullWidth
          disableListWrap
          ListboxComponent={VirtualizedListBox}
          options={props.options}
          renderInput={(params) => <TextField {...params} label={t(props.label)}/>}
          getOptionLabel={option => option.name}
          renderOption={(props, option, state) =>
            [props, option, state.index] as ReactNode
          }
          renderGroup={(params) => params as any}
          onChange={(event, value) => handleChange(event,value)}
        />
      </Box>
      <Stack>
        {
          selectedCountries.map(country => <Box className='selected-country' key={country.name}>
              <Typography variant='body1' className='country-name'>{country.name}</Typography>
            <IconButton onClick={() => removeCountry(country)}><CloseIcon/></IconButton>
            </Box>
          )
        }
      </Stack>
    </Stack>
  );
}

export default CountriesMultiSelect;
