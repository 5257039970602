import { CardContent, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import KycCard from '../../shared/components/KycCard/KycCard';
import MainPage from '../../shared/pages/main-page/MainPage';
import './aml-submitted-page.scss';

function AmlSubmittedPage() {
  const { t } = useTranslation();

  return <MainPage>
    <KycCard id='aml-submitted-card'>
      <CardContent>
        <Stack id='aml-submitted-page'>
          <Typography variant='h2' className='title'>
            {t('AML_SUCCESSFULLY_SUBMITTED')}
          </Typography>
          <Typography variant='h6' className='description'>
            {t('AML_SUBMITTED_SUCCESSFULLY_MESSAGE')}
          </Typography>
          <Button variant='contained' color='secondary' className='aml-btn'>
            {t('CONTACT')}
          </Button>
        </Stack>
      </CardContent>
    </KycCard>
  </MainPage>;
}

export default AmlSubmittedPage;
