import { AxiosResponse } from 'axios';
import axios from '../axios/axios';
import { KycProcedureLegalEntityAnswersRequest } from '../models/legal_entity/KycProcedureLegalEntityAnswersRequest';
import { RepresentativeAnswersRequest } from '../models/legal_entity/RepresentativeAnswersRequest';
import { ShareholderRequest } from '../models/legal_entity/ShareholderRequest';
import KYCProcedure from '../models/procedure/KYCProcedure';
import { KycProcedureProgress } from '../models/procedure/KycProcedureProgress';
import { QRCodeResponse } from '../models/procedure/QRCodeResponse';
import { ProcedureType } from '../pages/LandingPage/constants/ProcedureType';

const KYCProcedureService = {
  async createKYCProcedure(tokenParam: string): Promise<KYCProcedure> {
    const response = await axios.post(`/kyc-procedure/public/create?tokenParam=${tokenParam}`);
    return response.data;
  },

  QRCode(journeyDef: string, scanningToken: string, customerNumber: string) {
    return axios.post<QRCodeResponse>('/kyc-procedure/public/gbg-qr-code', {
      journeyDef: journeyDef,
      scanningToken: scanningToken,
      customerNumber: customerNumber
    });
  },

  findKycProcedureProgressByToken(token: string): Promise<AxiosResponse<KycProcedureProgress>> {
    return axios.get(`/kyc-procedure/public/progress?token=${token}`);
  },
  async setIndividualType(id: number) {
    await axios.post('/kyc-procedure/public/set-individual-type/' + id);
  },
  async setLegalEntityType(id: number) {
    await axios.post('/kyc-procedure/public/set-legal-entity-type/' + id);
  },
  async createLegalEntityAnswers(token: string, request: KycProcedureLegalEntityAnswersRequest) {
    await axios.post('/kyc-procedure/public/create-legal-entity-answers', request, { params: { token } });
  },
  async createRepresentativeAnswers(token: string, request: RepresentativeAnswersRequest) {
    await axios.post('/kyc-procedure/public/create-representative-answers', request, { params: { token } });
  },
  async createShareholders(token: string, request: ShareholderRequest[]) {
    await axios.post('/kyc-procedure/public/create-shareholders', request, { params: { token } });
  },
  async getProcedureTypeByToken(token: string): Promise<ProcedureType> {
    const response = await axios.get(`/kyc-procedure/public/procedure-type?token=${token}`);
    return response.data;
  },
  async existsByToken(token: string): Promise<boolean> {
    const response = await axios.get(`/kyc-procedure/public/exists-by-token?token=${token}`);
    return response.data;
  }
};

export default KYCProcedureService;
