import { Typography } from '@mui/material';
import React from 'react';
import { FileUploader } from 'react-drag-drop-files';
import './FileUploadInput.scss';
import { UseFormRegister, UseFormWatch } from 'react-hook-form';
import FileDTO from '../../models/clientQuestionnaires/FileDTO';

interface FileUploadInputProps {
  label: string;
  name?: string; // Name of the input for react-hook-form
  selectedFiles: File[] | FileDTO[];
  onFileChange: (files: File[], index: number) => void;
  index: number;
  register?: UseFormRegister<any>;
  watch?: UseFormWatch<any>;
  classes?: string;
  onSizeErrorModal?: (errorMessage: string) => void;
}

const FileUploadInput: React.FC<FileUploadInputProps> = ({
  label,
  name,
  selectedFiles,
  onFileChange,
  index,
  classes,
  register, // Destructure the register function from props,
  onSizeErrorModal
}) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleFileChange = (files: any) => {
    onFileChange(Array.from(files), index);
  };

  return (
    <div className={`file-uploader ${classes}`}>
      <Typography variant='body1'>{label}</Typography>
      <FileUploader
        handleChange={handleFileChange}
        multiple={true}
        name='files'
        maxSize={10}
        onSizeError={onSizeErrorModal}
        onClick={() => {
          if (fileInputRef.current) {
            fileInputRef.current.click();
          }
        }}
        dropMessageStyle={{ backgroundColor: '#fff' }}/>
      <div className='selected-files-list'>
        {selectedFiles?.length > 0 &&
          selectedFiles.map((e) => e.name).join(', ')}
      </div>
    </div>
  );
};

export default FileUploadInput;
