import {Box, Button, Checkbox, Grid, Typography} from '@mui/material';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {FileName} from '../enums/FileName';
import {TermsAndConditionsProps} from './TermsAndConditionsProps';
import {useTranslation} from 'react-i18next';

const TermsAndConditions = (props: TermsAndConditionsProps) => {

    const [checkedState, setCheckedState] = useState([false, false, false, false]);
    const {t} = useTranslation();

    const handleCheckboxChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedCheckedState = [...checkedState];
        updatedCheckedState[index] = event.target.checked;
        setCheckedState(updatedCheckedState);
        props.onCheckboxChange(index, event.target.checked);
    };

    return (
        <>
            <Grid item xs={12}>
                <Typography variant='h6' className='step-subtitle'>
                    {t('TERMS_AND_CONDITIONS')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container item>
                    <Grid item xs={12} alignItems={'center'} display={'flex'}>
                        <Checkbox required onChange={handleCheckboxChange(0)}
                                  sx={{
                                      '& .MuiSvgIcon-root': {fontSize: 28}
                                  }}/>
                        {t('CORRECT_PERSONAL_INFO')}
                    </Grid>
                    <Grid item xs={12} display={'flex'} alignItems={'center'}>
                        <Checkbox required onChange={handleCheckboxChange(1)}
                                  sx={{
                                      '& .MuiSvgIcon-root': {fontSize: 28}
                                  }}/>
                        {t('S2T_ABLE_TO_CONTACT')}
                    </Grid>
                    <Grid item xs={12} display={'flex'} alignItems={'center'}>
                        <Checkbox required onChange={handleCheckboxChange(2)}
                                  sx={{
                                      height: '100%',
                                      '& .MuiSvgIcon-root': {fontSize: 28}
                                  }}/>
                        <Box>
                            {t('INFO_SEND_TO_THIRD_PART')} &nbsp;
                            <Link
                                to={`/view-pdf/${FileName.PRIVACY_POLICY}/view`}
                                className={'link link-element privacy-link'} target={'_blank'}>
                                Safe2Transact Privacy Policy</Link>,<Link
                            to={`/view-pdf/${FileName.COOKIES_POLICY}/view`}
                            className={'link link-element privacy-link'}
                            target={'_blank'}> Cookie
                            policy</Link> and <Link to={`/view-pdf/${FileName.TOKEN_PRE_SALE}/view`}
                                                    className={'link link-element privacy-link'}
                                                    target={'_blank'}>Token Sale
                            policy.</Link>
                        </Box>
                    </Grid>
                    <Grid item xs={12} display={'flex'} alignItems={'center'}>
                        <Checkbox onChange={handleCheckboxChange(3)}
                                  sx={{
                                      '& .MuiSvgIcon-root': {fontSize: 28}
                                  }}/>
                        {t('PEP_CONFIRM')} ( <Link
                        to={`/view-pdf/${FileName.POLITICAL_EXPOSED_PERSON_INFO}/view`}
                        className={'link link-element privacy-link'}
                        target={'_blank'}>Political
                        Exposed Persons</Link> ).
                    </Grid>
                    <Grid item xs={12} alignItems={'center'} display={'flex'}>
                        <Checkbox required onChange={handleCheckboxChange(4)}
                                  sx={{
                                      '& .MuiSvgIcon-root': {fontSize: 28}
                                  }}/>
                        {t('BENEFICIARY_CONFIRMATION')}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{mt: 1}} display='flex' justifyContent='center'>
                <Button
                    disabled={!(checkedState[0] && checkedState[1] && checkedState[2])}
                    variant='contained'
                    onClick={props.submitVerification}
                    className='basic-button'>
                    {t('SUBMIT_VERIFICATION')}
                </Button>
            </Grid>
        </>
    );
};
export default TermsAndConditions;
