import { Button, Grid, TextField } from '@mui/material';
import React, { useState } from 'react';
import FileUploadInput from '../../../components/FormFIelds/FIleUploadInput';
import SwalAlert from '../../../components/SwalAlert/SwalAlert';
import { RepresentativeAnswersRequest } from '../../../models/legal_entity/RepresentativeAnswersRequest';
import KYCProcedureService from '../../../repository/KYCProcedureService';
import ApplicationService from '../../../utils/ApplicationService';
import { ErrorHandler } from '../../../utils/ErrorHandler';
import { RepresentativeAnswersProps } from './RepresentativeAnswersProps';
import { useTranslation } from 'react-i18next';

export function RepresentativeAnswers({
  token,
  onSave
}: RepresentativeAnswersProps) {
  const { t } = useTranslation();
  const [request, setRequest] = useState<RepresentativeAnswersRequest>({
    workingPosition: '',
    email: '',
    phoneNumber: '',
    percentageOfSharing: '',
    powerOfAttorney: []
  });
  const disableButton =
    request.workingPosition.trim() === '' ||
    request.email.trim() === '' ||
    request.phoneNumber.trim() === '' ||
    request.percentageOfSharing === '' ||
    request.powerOfAttorney.length === 0;
  const [emailError, setEmailError] = useState(false);

  const handleChangePowerOfAttorney = (files: File[]) => {
    ApplicationService.filesToFilesDto(files).then((res) => {
      setRequest(prevState => ({ ...prevState, powerOfAttorney: res }));
    });
  };

  const saveRepresentativeAnswers = () => {
    KYCProcedureService.createRepresentativeAnswers(token!, request).then((res) => {
      onSave();
    }).catch((e) => {
      const errorMessage = ErrorHandler.parseErrorMessage(e);
      let customMessage = t('ERROR_SAVING_REPRESENTATIVE_ANSWERS');
      SwalAlert.errorAlert(t('ERROR'), errorMessage + ' ' + customMessage);
    });
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRequest(prevState => ({ ...prevState, email: event.target.value }));
    if (event.target.validity.valid) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };

  return (
    <Grid container spacing={2} padding={4}>
      <Grid item xs={12} sm={12} lg={6}>
        <TextField
          label={t('WORKING_POSITION')}
          fullWidth={true}
          value={request.workingPosition}
          onChange={(event) => setRequest(prevState => ({ ...prevState, workingPosition: event.target.value }))}/>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <TextField
          label={t('EMAIL')}
          fullWidth={true}
          error={emailError}
          helperText={emailError && t('EMAIL_CORRECT_FORMAT')}
          inputProps={{
            type: "email",
          }}
          value={request.email}
          onChange={handleEmailChange}/>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <TextField
          type={'number'}
          label={t('PHONE_NUMBER')}
          fullWidth={true}
          value={request.phoneNumber}
          onChange={(event) => setRequest(prevState => ({ ...prevState, phoneNumber: event.target.value }))}/>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <TextField
          label={t('PERCENTAGE_OF_SHARING')}
          fullWidth={true}
          value={request.percentageOfSharing}
          type='number'
          onChange={(event) => setRequest(prevState => ({ ...prevState, percentageOfSharing: event.target.value }))}/>
      </Grid>
      <Grid item xs={12} sm={12} lg={12}>
        <FileUploadInput
          classes='file-upload-box'
          label={t('POWER_OF_ATTORNEY')}
          selectedFiles={request.powerOfAttorney}
          onFileChange={handleChangePowerOfAttorney}
          index={0}/>
      </Grid>
      <Grid
        item
        xs={12}
        className='legal-entity-next-button'
        display='flex'>
        <Button
          disabled={disableButton}
          variant='contained'
          onClick={saveRepresentativeAnswers}>
          {t('NEXT')}
        </Button>
      </Grid>
    </Grid>
  );
}
