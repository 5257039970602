import AddIcon from '@mui/icons-material/Add';
import { Box, Button, CardContent, Grid, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import KYCProcedureService from 'repository/KYCProcedureService';
import { FullPageLoadingCircle } from '../../components/FullPageLoadingCircle/FullpageLoadingCircle';
import SwalAlert from '../../components/SwalAlert/SwalAlert';
import { FileDTO } from '../../models/aml/FileDTO';
import { ClientBasicData } from '../../models/clients/ClientBasicData';
import { RepresentativeAnswersResponse } from '../../models/legal_entity/RepresentativeAnswersResponse';
import { ShareholderRequest } from '../../models/legal_entity/ShareholderRequest';
import { QRCodeResponse } from '../../models/procedure/QRCodeResponse';
import { ClientTokenService } from '../../repository/ClientTokenService';
import GBGTokenService from '../../repository/GBGTokenService';
import RepresentativeAnswersService from '../../repository/RepresentativeAnswersService';
import { TermsConditionService } from '../../repository/TermsConditionService';
import KycCard from '../../shared/components/KycCard/KycCard';
import MainPage from '../../shared/pages/main-page/MainPage';
import { ErrorHandler } from '../../utils/ErrorHandler';
import GbgJourneyContainer from '../GbgJourneyContainer';
import { individualKycSteps } from './constants/IndividualKycSteps';
import { legalEntityKycSteps } from './constants/LegalEntityKycSteps';
import { ProcedureType } from './constants/ProcedureType';
import './LandingPage.scss';
import IdentityVerificationSteps from './IdentityVerificationSteps/IdentityVerificationSteps';
import { KycProcedureStep } from './interfaces/KycProcedureStep';
import { LandingPageStep } from './LandingPageStep';
import { LegalEntityForm } from './LegalEntityForm/LegalEntityForm';
import { ProofOfAddress } from './ProofOfAddress/ProofOfAddress';
import { RepresentativeAnswers } from './RepresentativeAnswers/RepresentativeAnswers';
import { Shareholder } from './Shareholders/Shareholder';
import TermsAndConditions from './TermsAndConditions/TermsAndConditions';

const LandingPage = () => {
  const navigate = useNavigate();
  const [steps, setSteps] = useState<KycProcedureStep[]>(individualKycSteps);
  const [activeStep, setActiveStep] = useState<KycProcedureStep | null>(null);
  const [userData, setUserData] = useState<ClientBasicData>();
  const [scanningToken, setScanningToken] = useState<string>('');
  const [filesForTermsAndConditionals, setFilesForTermsAndConditionals] = useState<FileDTO[]>([]);
  const { token } = useParams();
  const [loading, setLoading] = useState(false);
  const [qrCode, setQrCode] = useState<QRCodeResponse | undefined>();
  const { t } = useTranslation();
  const [procedureType, setProcedureType] = React.useState<ProcedureType | null>(null);
  const [shareholders, setShareholders] = useState<ShareholderRequest[]>(
    [{ firstName: '', lastName: '', email: '', phoneNumber: '', shareholderType: '', shareholderRole: '' }]);
  const [representativeAnswersResponse, setRepresentativeAnswersResponse] = useState<RepresentativeAnswersResponse>();

  const [checkboxValues, setCheckboxValues] = useState([false, false, false, false, false]);

  function updateQRCode(qrCode: QRCodeResponse): void {
    setQrCode(qrCode);
  }

  const navigateToKycSubmitted = () => navigate('/kyc-submitted');

  const setShareholder = (index: number, shareholder: ShareholderRequest) => {
    const newShareholdersList = shareholders?.map((e, i) => {
      if (index === i) {
        return shareholder;
      }
      return e;
    });

    setShareholders(newShareholdersList);
  };

  const updateActiveStep = (step: LandingPageStep) => {
    const stepItem = steps.find(el => el.type === step)!;
    setActiveStep(stepItem);
  };

  const validateToken = useCallback(async (token: string) => {
    await ClientTokenService.validateClientToken(token)
      .then((response) => {
        setUserData(response.data);
      })
      .catch((e) => {
        const errorMessage = ErrorHandler.parseErrorMessage(e);
        const customMessage = t('START_NEW_KYC_PROCEDURE');
        SwalAlert.errorAlert(t('ERROR'), errorMessage + ' ' + customMessage);
      });
  }, []);

  useEffect(() => {
    getFileForTermsAndConditions();

    function hasGbgResponseByProcedureToken(procedureType: ProcedureType) {
      KYCProcedureService.findKycProcedureProgressByToken(token!).then((res) => {
        const { isCompleted, isGbgCompleted } = res.data;
        if (isCompleted || isGbgCompleted) {
          clearInterval(interval);
        }
        if (isCompleted) {
          navigateToKycSubmitted();
        } else if (isGbgCompleted) {
          if (procedureType === ProcedureType.LEGAL_ENTITY) {
            getRepresentativeAnswersData();
          }
          handleJourneyCompleted();
        }
      });
    }

    let interval: any;
    if (procedureType) {
      hasGbgResponseByProcedureToken(procedureType!);
      interval = setInterval(() => hasGbgResponseByProcedureToken(procedureType!), 5000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [procedureType]);

  const getFileForTermsAndConditions = () => {
    TermsConditionService.getFilesForTermsAndConditions().then(
      (response: FileDTO[]) => setFilesForTermsAndConditionals(response)
    );
  };

  const accessDenied = async () => {
    navigate('/denied');
  };

  const getRepresentativeAnswersData = () => {
    RepresentativeAnswersService.getByKycProcedureToken(token!).then((res) => {
      setRepresentativeAnswersResponse(res);
    });
  };

  useEffect(() => {
    if (!token) {
      accessDenied();
      return;
    }
    if (scanningToken === '') {
      GBGTokenService.generateScanningToken().then((res) => {
        setScanningToken(res);
      });
    }
    KYCProcedureService.getProcedureTypeByToken(token).then((res) => {
      if (res) {
        updateProcedureType(res);
      }
    });

    KYCProcedureService.findKycProcedureProgressByToken(token).then((res) => {
      const { procedureTypeChosen, isCompleted, isGbgCompleted, isLegalEntityCompleted, isRepresentativeAnswerCompleted, shareholdersCompleted } = res.data;
      if (isCompleted) {
        navigateToKycSubmitted();
      } else if (!isCompleted) {
        validateToken(token).then(() => {
          if (procedureTypeChosen && !isGbgCompleted && !isLegalEntityCompleted && !isRepresentativeAnswerCompleted) {
            if (procedureType === ProcedureType.INDIVIDUAL) {
              updateActiveStep(LandingPageStep.ID_DOCUMENT);
            } else if (procedureType === ProcedureType.LEGAL_ENTITY) {
              updateActiveStep(LandingPageStep.LEGAL_ENTITY_ANSWERS);
            }
          } else if (isGbgCompleted) {
            if (procedureType === ProcedureType.LEGAL_ENTITY) {
              getRepresentativeAnswersData();
            }
            handleJourneyCompleted();
          } else if (shareholdersCompleted) {
            updateActiveStep(LandingPageStep.ID_DOCUMENT);
          } else if (isRepresentativeAnswerCompleted) {
            updateActiveStep(LandingPageStep.SHAREHOLDERS);
          } else if (isLegalEntityCompleted) {
            updateActiveStep(LandingPageStep.REPRESENTATIVE_ANSWERS);
          }
        });
      }
    });
  }, [validateToken, scanningToken]);

  const handleJourneyCompleted = async () => {
    validateToken(token!).then(() => {
      updateActiveStep(LandingPageStep.PROOF_OF_ADDRESS);
    });

  };

  const submitVerification = () => {
    setLoading(true);
    TermsConditionService.termsAndConditionsVerification(
      checkboxValues[3],
      checkboxValues[4],
      true,
      true,
      true,
      token!
    ).then(() => {
      setLoading(false);
      navigateToKycSubmitted();
    }).catch(() => setLoading(false));
  };

  const handleAddShareholder = () => {
    setShareholders(shareholders => [...shareholders, { firstName: '', lastName: '', email: '', phoneNumber: '', shareholderType: '', shareholderRole: '' }]);
  };

  const handleRemoveShareholder = (index: number) => {
    const newShareholdersList = shareholders.filter((s, i) => {
      return i !== index;
    });
    setShareholders(newShareholdersList);
  };

  const saveShareholders = () => {
    KYCProcedureService.createShareholders(token!, shareholders).then((res) => {
            updateActiveStep(LandingPageStep.ID_DOCUMENT);
          })
        .catch((error) => {
          SwalAlert.errorAlert(t('OPS_MESSAGE'), error.response.data);
        });
  };
  const isValidationSuccessful = (): boolean => {
    let isValid = true;
    for (let i = 0; i < shareholders.length; i++) {
      if (shareholders[i].firstName.trim() === '' ||
        shareholders[i].lastName.trim() === '' ||
        shareholders[i].email.trim() === '' ||
        shareholders[i].phoneNumber.trim() === '' ||
        shareholders[i].shareholderType.trim() === '' ||
        shareholders[i].shareholderRole.trim() === '') {
        isValid = false;
        break;
      }
    }
    if (shareholders.length === 0) {
      isValid = false;
    }
    return isValid;
  };

  const updateProcedureType = (procedureType: ProcedureType) => {
    setProcedureType(procedureType);
    const steps = procedureType === ProcedureType.INDIVIDUAL ? individualKycSteps : legalEntityKycSteps;
    setSteps(steps);
  };

  const renderStep = (step: KycProcedureStep) => {
    return <Step index={step.step} key={step.label} completed={!!activeStep && activeStep.step > step.step} component={'div'}>
      <StepLabel></StepLabel>
      <Typography fontWeight={600}>{t(step.label)}</Typography>
    </Step>;
  };

  const handleCheckboxChange = (index: number, checked: boolean) => {
    const updatedCheckedState = [...checkboxValues];
    updatedCheckedState[index] = checked;
    setCheckboxValues(updatedCheckedState);
  };

  return (
    <MainPage>
      {!activeStep && <KycCard className='initiate-kyc-card'>
        <CardContent>
          <Stack>
            <Typography variant='h2' id='step-title'>
              {t('INITIATE_KYC_PROCESS')}
            </Typography>
            <Typography variant='body1' className='kyc-step-description'>{t('KYC_PROCESS_DESCRIPTION')}</Typography>
            <Button variant='contained' className='initiate-kyc-btn' onClick={() => setActiveStep(individualKycSteps[0])}>{t('INITIATE_KYC')}</Button>
          </Stack>
        </CardContent>
      </KycCard>}
      {activeStep && <KycCard>
        <CardContent>
          <Stack>
            <Typography variant='h2' id='step-title'>
              {t('IDENTITY_VERIFICATION_STEPS_TITLE')}
            </Typography>
            <Stepper activeStep={activeStep?.step} connector={null} className='stepper'>
              {
                steps.map(step => renderStep(step))
              }
            </Stepper>

            {activeStep.type === LandingPageStep.IDENTITY_VERIFICATION && (
              <IdentityVerificationSteps token={token}
                                         onProcedureTypeChange={(procedureType) => updateProcedureType(procedureType)}
                                         onNextStep={() => {
                                           const nextActiveStep = procedureType === ProcedureType.INDIVIDUAL ? LandingPageStep.ID_DOCUMENT : LandingPageStep.LEGAL_ENTITY_ANSWERS;
                                           updateActiveStep(nextActiveStep);
                                         }}/>
            )}
            {activeStep.type === LandingPageStep.LEGAL_ENTITY_ANSWERS && (
              <Grid container className='middle-content' justifyContent='center' alignItems='center'>
                <Grid item container xs={12} justifyContent='center' alignItems='center' alignSelf={'center'}>
                  <Grid item>
                    <Typography variant='h6' className='step-subtitle'>
                      {t('LEGAL_ENTITY_INFO')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <LegalEntityForm
                      email={userData?.email}
                      token={token}
                      onSave={() => updateActiveStep(LandingPageStep.REPRESENTATIVE_ANSWERS)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            {activeStep.type === LandingPageStep.REPRESENTATIVE_ANSWERS && (
              <Grid container className='middle-content' justifyContent='center' alignItems='center'>
                <Grid
                  item
                  container
                  xs={12}
                  justifyContent='center'
                  alignItems='center'
                  alignSelf={'center'}>
                  <Grid item>
                    <Typography variant='h6' className='step-subtitle'>
                      {t('REPRESENTATIVE')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <RepresentativeAnswers
                      token={token}
                      onSave={() => updateActiveStep(LandingPageStep.SHAREHOLDERS)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            {activeStep.type === LandingPageStep.SHAREHOLDERS && (
              <Grid container className='middle-content'>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Typography variant='h6' className='step-subtitle'>
                      {t('INPUT_SHAREHOLDERS')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {shareholders.map((shareholder, index) => {
                      return (
                        <Shareholder
                          key={index}
                          shareholder={shareholder}
                          index={index}
                          onChange={setShareholder}
                          removeItem={handleRemoveShareholder}
                        />
                      );
                    })}
                  </Grid>
                  <Grid item xs={12} className='shareholder-operations'>
                    <Button variant='outlined'
                            color='primary'
                            startIcon={<AddIcon/>}
                            className='add-shareholder-btn'
                            onClick={handleAddShareholder}>Add shareholder</Button>
                  </Grid>
                  <Grid item xs={12} className='legal-entity-next-button' display='flex'>
                    <Button
                      disabled={!isValidationSuccessful()}
                      variant='contained'
                      onClick={saveShareholders}>
                      {t('NEXT')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {activeStep.type === LandingPageStep.ID_DOCUMENT && (
              <Grid container className='middle-content'>
                <Grid item container rowGap={2}>
                  <Grid item xs={12}>
                    <Typography id='step-title' variant='body1'>{t('APPROVED_IDENTITY_DOCUMENTS')}</Typography>
                    <Typography variant='body1' className='kyc-step-description'>{t('IDENTITY_CHECK_DESCRIPTION')}</Typography>
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item lg={8} sm={12}>
                      <Box className='gbg-box'>
                        {scanningToken !== '' && <GbgJourneyContainer
                          scanningToken={scanningToken}
                          onJourneyCompleted={handleJourneyCompleted}
                          userEmail={userData?.email}
                          clientToken={token}
                          onQRCodeReceived={updateQRCode}
                        />}
                      </Box>
                    </Grid>
                    <Grid item lg={4} sm={12}>
                      <Box className='qr-box'>
                        <Typography variant='body1' fontWeight={700}>{t('SCAN_QR_TITLE')}</Typography>
                        <img className='kyc-qr' src={`data:image/png;base64,${qrCode?.data.QR_Code}`} alt='qr-code'/>
                        <Typography variant='body1' className='qr-info'>{t('SCAN_QR_INFO')}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {activeStep.type === LandingPageStep.PROOF_OF_ADDRESS && (
              <Grid container className='middle-content'>
                <Grid item container xs={12}>
                  <Grid item xs={12}>
                    <Typography variant='h6' className='step-subtitle'>
                      {t('PROOF_OF_ADDRESS')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {((procedureType === ProcedureType.INDIVIDUAL && userData) || (procedureType === ProcedureType.LEGAL_ENTITY && userData && representativeAnswersResponse)) &&
                      <ProofOfAddress
                        token={token}
                        fullName={userData?.fullName}
                        email={procedureType === ProcedureType.INDIVIDUAL ? userData?.email : representativeAnswersResponse?.email}
                        gbgBirthDate={userData?.birthDate}
                        onSave={() => updateActiveStep(LandingPageStep.TERMS_AND_CONDITIONS)}
                        phoneNumber={userData?.phoneNumber}
                      />}
                  </Grid>
                </Grid>
              </Grid>
            )}
            {activeStep.type === LandingPageStep.TERMS_AND_CONDITIONS && (
              <Grid container className='middle-content'>
                <Grid item xs={12} className='terms-and-conditions-box'>
                  <TermsAndConditions submitVerification={submitVerification} filesForTermsAndConditionals={filesForTermsAndConditionals}
                                      onCheckboxChange={handleCheckboxChange}/>
                </Grid>
              </Grid>
            )}
          </Stack>
        </CardContent>
      </KycCard>}
      <FullPageLoadingCircle loading={loading}/>
    </MainPage>
  );
};

export default LandingPage;
